<template lang="html">

  <section class="footer">
    <div class="p-3-rem">
      <div class="grid-4 container">
        <div class="grid">
          <img :src="urlLogo" alt="logo">
        </div>
        <div class="grid">
          <ul class="list-style-none font-family-frank-ruhl font-size-21 text-primary">
            <li>
              <router-link to="/que-es-cermi" title="Ir a página de ¿Que es Cermi?" alt="Ir a página de ¿Que es Cermi?">Conocer más sobre el CERMI</router-link>
            </li>
            <li>
              <router-link to="/iniciativas-cermi" title="Ir a página de Iniciativas CERMI" alt="Ir a página de Iniciativas CERMI">Iniciativas CERMI</router-link>
            </li>
            <li>
              <router-link to="/actualidad" title="Ir a página de Actualidad CERMI" alt="Ir a página de Actualidad CERMI">Actualidad y comunicación</router-link>
            </li>
            <li>
              <router-link to="/transparencia" title="Ir a página de Transparencia CERMI" alt="Ir a página de Transparencia CERMI">Transparencia</router-link>
            </li>
            <li>
              <router-link to="/normas-reguladoras/observancia-normativa-en-el-seno-del-cermi-estatal-denuncia-de-ilicitos" 
              title="Ir a página de Canal Ético CERMI" alt="Ir a página de Canal Ético CERMI">
                Canal Ético
              </router-link>
            </li>
          </ul>
        </div>
        <div class="grid">
          <ul class="list-style-none font-family-frank-ruhl font-size-21 text-primary">
            <li>
              <a href="https://sedeelectronica.cermi.es/" target="_blank" title="Ir a página de Sede electrónica CERMI" alt="Ir a página de Sede electrónica CERMI">Sede electrónica</a>
            </li>
            <li>
              <router-link to="/bizum" title="Ir a página de Donativos CERMI" alt="Ir a página de Donativos CERMI">Donativos CERMI Estatal</router-link>
            </li>
            <li>
              <a href="https://diario.cermi.es" target="_blank" title="Ir a página CERMI.ES Diario" alt="Ir a página CERMI.ES Diario">CERMI.ES Diario</a>
            </li>
            <li>
              <a href="https://www.fundacioncermimujeres.es/" target="_blank" title="Ir a página CERMI Mujeres" alt="Ir a página CERMI Mujeres">CERMI Mujeres</a>
            </li>

          </ul>
        </div>
        <div class="grid">
          <ul class="list-style-none font-family-roboto font-light font-size-16">
            <li class="p-10-px d-flex">
              <i class="fa  fa-map-marker text-primary"></i>
              <span class="p-left-16-px d-inline-block">Calle Recoletos, 1 Bajo, 28001 Madrid, España</span>
            </li>
            <li class="p-10-px">
              <i class="fa fa-phone text-primary"></i>
              <span class="p-left-16-px">Teléfono.: <a href="tel:+913601678">91 360 16 78</a></span>
            </li>
            <li class="p-10-px">
              <i class="fa fa-envelope-o text-primary"></i>
              <span class="p-left-16-px">Correo electrónico: <a href="mailto:CERMI@CERMI.es">cermi@cermi.es</a></span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="background-blue-5 footer-sub">
      <div class="container grid-2 justify-content-center align-items-center ">
        <div class="grid w-65">
          <ul class="h-100 text-primary list-style-none d-flex justify-content-between align-items-center">
            <li>
              <router-link class="text-decoration-none text-primary" :to="{name:'accesibilidad'}" title="Ir a página de Accesibilidad" alt="Ir a página de Accesibilidad">Accesibilidad</router-link>
            </li>
            <li>
              <router-link class="text-decoration-none text-primary" :to="{name:'aviso-legal'}" title="Ir a página de Accesibilidad" alt="Ir a página de Accesibilidad">Aviso Legal</router-link>
            </li>
            <li>
              <router-link class="text-decoration-none text-primary" :to="{name:'politica-privacidad'}" title="Ir a página de Protección de datos y privacidad" alt="Ir a página de Protección de datos y privacidad">Protección de datos y privacidad</router-link>
            </li>
            <li>
              <router-link class="text-decoration-none text-primary" :to="{name:'politica-cookies'}" title="Ir a página de Información sobre pre-registros informáticos" alt="Ir a página de Información sobre pre-registros informáticos">Información sobre pre-registros informáticos</router-link>
            </li>
            <!-- <li>Mapa web</li> -->
          </ul>
        </div>
        <div class="grid w-30">
          <div class="rss">
            <span class="font-family-roboto font-light" title="Síguenos en" alt="Síguenos en">Síguenos en</span>
            <a href="https://www.facebook.com/Cermi-Estatal-338031889614106/" title="Link a Facebook" alt="Link a " target="_blank">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="https://twitter.com/Cermi_Estatal" title="Link a X" alt="Link a X" target="_blank">
              <i class="fa-brands fa-x-twitter" aria-hidden="true"></i>
            </a>
            <a href="https://www.instagram.com/cermi_estatal/" title="Link a Instagram" alt="Link a Instagram" target="_blank">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>

            <a href="https://es.linkedin.com/in/cermi-estatal-8ab39655" title="Link a Linkedin" alt="Link a Linkedin" target="_blank">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a href="https://www.youtube.com/user/CERMIESTATAL/videos" title="Link a Youtube" alt="Link a Youtube" target="_blank">
              <i class="fa fa-youtube" aria-hidden="true"></i>
            </a>
            <a href="https://t.me/cermiestatal" title="Link a Telegram" alt="Link a Telegram" target="_blank">
              <i class="fa fa-telegram" aria-hidden="true"></i>
            </a>
            <a href="https://www.tiktok.com/@cermiestatal" title="Link a Tiktok" alt="Link a Tiktok" target="_blank">
              <i class="fa-brands fa-tiktok" aria-hidden="true"></i>
            </a>
            <a
              href="https://bsky.app/profile/cermiestatal.bsky.social"
              title="Link a bluesky"
              alt="Link a bluesky"
              target="_blank"
            >
             <img src="/img/icons/bluesky-white.svg" alt="Link a bluesky">
             <img src="/img/icons/bluesky-blue.svg" alt="Link a bluesky" class="img-hover"> 

            </a>
          </div>
        </div>
      </div>

    </div>
  </section>

</template>

<script lang="js">
  export default {
    name: 'FooterLayout',
    props: [],
    data() {
      return {
        urlLogo: '/img/logo.jpg',
      }
    },
    mounted() {
      const fechaActual = new Date().getTime();
      const fechaFin = new Date(2023, 6, 15).getTime();
      if (fechaActual >= fechaFin) {
        this.urlLogo = '/img/logo.jpg'
      }
    },
  }

</script>
<style scoped lang="scss">
  @import "@/styles/general.scss";

  .footer-sub {
    padding: 10px 0;
  }

  .footer {
    a {
      text-decoration: none;
      color: $blue;
    }

    span a {
      color: black;
    }
  }

  @media screen and (max-width:895px) {
    .footer .grid img {
      max-width: 180px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 19px;
    }
    .footer .grid .rss a img {
    max-width: 15px !important;
    padding-top: 5px;
    margin-bottom: 0 !important;
}

    .footer a {
      text-decoration: none;
      color: #0069B4;
      font-size: 18px;
      margin-bottom: 10px;
      display: inline-block;
    }

    .footer .grid li {
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .footer .grid-4 {
      padding: 0;

      .p-10-px {
        padding: 10px 0;
      }
    }

    .footer-sub {
      padding: 20px 0;
    }
  }
</style>